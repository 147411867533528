import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosClient from '../../axios';
import useIsInViewport from '../../useIsInViewport';
import processing from "../../assets/images/processing.png"


function Category() {
    const load = useRef(null);
    const lastVoucherRef = useRef(null);
    const [preventRefresh, setPreventRefresh] = useState(false);

    const navigate = useNavigate();
    // const isInViewport1 = useIsInViewport(load);
    const [isEnd, setIsEnd] = useState(false);
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const [vouchers, setVouchers] = useState([])
    const getVouchers = async (skip) => {
        setLoader(true);
        setPreventRefresh(true);
        await axiosClient.get(`/customers/api/vouchers/${skip}/6`)
            .then((res) => {
                setVouchers(res.data.response);

                // setCurrentPage(currentPage + 1)
                // setVouchers([...vouchers, ...res.data.response]);
                // if (res.data.response.length === 0) {
                //     setIsEnd(true);
                //     return;
                // }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoader(false);
                setPreventRefresh(false);
                // if (lastVoucherRef.current) {
                //     lastVoucherRef.current.scrollIntoView({ behavior: 'smooth' });
                // }
            })

    }

    useEffect(() => {
        const preventPullToRefresh = (event) => {
            if ((event.touches.length > 1 || shouldPreventDefault(event)) && preventRefresh) {
                event.preventDefault();
            }
        };

        const shouldPreventDefault = (event) => {
            return true; // Adjust this condition accordingly
        };

        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

        return () => {
            document.removeEventListener('touchmove', preventPullToRefresh);
        };
    }, [preventRefresh]);


    useEffect(() => {

        getVouchers(0);

    }, [])

    // useEffect(() => {
    //     if (isInViewport1) {
    //         getVouchers(currentPage);
    //     }
    // }, [isInViewport1])

    return (
        <div className='categoryContainer'>

            {
                loader ? <>
                    <div className='centreProcess'>
                        <div className='processing pro'>
                            <img src={processing} alt="processing" />
                        </div>
                        <h5 className='refreshText'>Please wait, don't refresh! We're getting new vouchers. Thank you for your patience.</h5>
                    </div>
                </> : <>
                    {
                        vouchers?.length > 0 ?
                            vouchers?.map((ele, index) => {
                                return (
                                    <React.Fragment key={Math.random()}>
                                        <h2 className='catgeoryName'  >{ele?.name}</h2>
                                        <div className='categorySlider'>
                                            <div className="allVouchers" >
                                                {
                                                    ele?.vouchers?.map((e) => {
                                                        return (
                                                            <React.Fragment key={e._id}>
                                                                <div className="voucher" onClick={() => { console.log("clicked"); navigate(`/voucher/${e?.sku}`) }}>
                                                                    <div className="voucherImage flex">
                                                                        <img src={e.image} alt="catImage" />
                                                                    </div>
                                                                    <div className='voucherDetail'>
                                                                        <p className='voucherPrice'>Rs. {e.price.denominations[0]}</p>
                                                                        <p className='voucherAmount'>{e.name}</p>
                                                                        <p className='expiryTime'>{`Expiry : ${!moment(e.expiry, moment.ISO_8601, true).isValid() ? e?.expiry : moment.utc(e?.expiry).format("MMM Do YYYY")}`}</p>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>)
                            }) : null
                    }
                </>}
            {/* {isEnd ? "" : (
                <>
                    <div className="Load_more" ref={load}>
                        <h3>
                            You're All caught up!
                        </h3>
                    </div>
                </>
            )} */}

        </div>
    )
}

export default Category